<template>
  <match-media v-slot="{ desktop }">
    <v-table-row class="villages-item" @click="() => desktop && edit()">
      <v-square-avatar class="villages-item__image" :src="photo" />

      <div class="villages-item__content">
        <div class="villages-item__title">{{ name }}</div>
        <div class="villages-item__adverts">{{ advertsTitle }}</div>
      </div>

      <div class="villages-item__actions" @click.stop>
        <template v-if="desktop">
          <v-button-icon v-if="isActive" class="villages-item__action" @click="archive">
            <v-icon-archive />
          </v-button-icon>
          <v-button-icon v-if="isArchived" class="villages-item__action" @click="activate">
            <v-icon-activate />
          </v-button-icon>
          <v-button-icon v-if="isDraft" class="villages-item__action " @click="remove">
            <v-icon-trash-bin />
          </v-button-icon>
        </template>

        <v-popover-actions v-else class="villages-item__popover">
          <v-button-icon class="villages-item__action villages-item__action--more">
            <v-icon-dots />
          </v-button-icon>
          <template #actions="{ onClick }">
            <v-button-wide @click="onClick(edit)">Редактировать</v-button-wide>
            <v-button-wide v-if="isActive" @click="onClick(archive)">Архивировать</v-button-wide>
            <v-button-wide v-if="isArchived" @click="onClick(activate)">Активировать</v-button-wide>
            <v-button-wide v-if="isDraft" @click="onClick(remove)">Удалить</v-button-wide>
          </template>
        </v-popover-actions>
      </div>
    </v-table-row>
  </match-media>
</template>

<script>
import VTableRow from '@/components/common/VTableRow.vue'
import VIconArchive from '@/components/icons/VArchive.vue'
import VIconActivate from '@/components/icons/VActivate.vue'
import VIconTrashBin from '@/components/icons/VTrashBin.vue'
import VIconDots from '@/components/icons/VDots.vue'
import VButtonIcon from '@/components/common/VButtonIcon.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'
import VPopoverActions from '@/components/common/VPopoverActions.vue'
import VSquareAvatar from '@/components/common/VSquareAvatar.vue'
import { MatchMedia } from 'vue-component-media-queries'
import { VILLAGE_ACTIVE_STATUS, VILLAGE_ARCHIVE_STATUS, VILLAGE_DRAFT_STATUS } from '@/constants/statuses/village'
import { pluralizeNumeral } from '@/utils/pluralization'

export default {
  name: 'VillagesListItem',
  components: {
    VSquareAvatar,
    VButtonIcon,
    VTableRow,
    VIconArchive,
    VIconTrashBin,
    VIconDots,
    VIconActivate,
    VButtonWide,
    VPopoverActions,
    MatchMedia
  },
  inheritAttrs: false,
  props: {
    advertsCount: { type: Number, required: true },
    name: { type: String, required: true },
    photo: { type: String, default: '' },
    status: { type: String, required: true }
  },
  computed: {
    isActive() {
      return this.status === VILLAGE_ACTIVE_STATUS
    },
    isArchived() {
      return this.status === VILLAGE_ARCHIVE_STATUS
    },
    isDraft() {
      return this.status === VILLAGE_DRAFT_STATUS
    },
    advertsTitle() {
      const count = this.advertsCount
      const text = pluralizeNumeral(count, 'объект', 'объекта', 'объектов')
      return `${count} ${text}`
    }
  },
  methods: {
    edit() {
      this.$emit('edit')
    },
    archive() {
      this.$emit('archive')
    },
    remove() {
      this.$emit('remove')
    },
    activate() {
      this.$emit('activate')
    }
  }
}
</script>
